import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import config from 'config';

function AdminLinks({
  rideId, userId, sharingToken, sessionIds, storageUrls,
}) {
  return (
    <>
      <a href={`${config.metabaseEvents}?session_ids=${sessionIds.join(',')}`}>Events</a>
      {sharingToken && (
        <>
          {' - '}
          <Link to={`/fr/p/${sharingToken}`}>
            Sharing
          </Link>
        </>
      )}
      {rideId && (
        <>
          {' - '}
          <a
            href={`${config.forestUrlRide}${rideId}/details`}
            target="_blank"
            rel="noopener noreferrer"
          >
            Forest Ride
          </a>
        </>
      )}
      {userId && (
        <>
          {' - '}
          <a
            href={`${config.forestUrlUser}${userId}/details`}
            target="_blank"
            rel="noopener noreferrer"
          >
            Forest User
          </a>
        </>
      )}
      {storageUrls.length > 0 && (
        <>
          <br />
          <br />
          {storageUrls.map(([label, storageUrl]) => (
            <React.Fragment key={storageUrl}>
              <a href={storageUrl}>{label}</a>
              {' '}
            </React.Fragment>
          ))}
        </>
      )}
      <br />
    </>
  );
}

AdminLinks.propTypes = {
  rideId: PropTypes.string,
  userId: PropTypes.string,
  sharingToken: PropTypes.string,
  sessionIds: PropTypes.arrayOf(PropTypes.string).isRequired,
  storageUrls: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.string)).isRequired,
};

AdminLinks.defaultProps = {
  rideId: null,
  userId: null,
  sharingToken: null,
};

export default AdminLinks;
