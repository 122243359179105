import React from 'react';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import { formatDateTime } from 'services/utils';

const Container = styled.div`
  max-height: 300px;
  overflow-y: auto;
  table, th, td {
    border: 1px solid #000;
  }
  th {
    background: #e4e4e4;
    font-weight: bold;
  }
  th, td {
    padding: 2px;
  }
  tr {
    background: white;
  }
  tr:hover {
    background: #e4e4e4;
  }
  a {
    text-decoration: none;
  }
  a:hover {
    text-decoration: underline;
  }
`;
const Id = styled.span`
  width: 80px;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: inline-block;
  overflow: hidden;
  vertical-align: bottom;
`;
const DetailsTd = styled.td`
  width: 600px;
  height: 25px;
  font-size: 9px;
  font-family: monospace;
  line-height: 1.3;
  cursor: pointer;
  pre {
    word-break: break-all;
    white-space: normal;
    overflow: hidden;
    max-height: 25px;
    max-width: 600px;
  }
  pre.expand {
    cursor: default;
    white-space: pre-wrap;
    overflow: auto;
    max-height: none;
    position: absolute;
    font-size: 10px;
    width: 600px;
    background: #f0f0f0;
    border: 1px solid #aaa;
    margin-top: -1px;
    margin-left: -1px;
  }
`;

function googleMapsLink(latitude, longitude) {
  return (
    <a target="_blank" rel="noopener noreferrer" href={`https://www.google.com/maps/place/${latitude},${longitude}/@${latitude},${longitude},150m/data=!3m1!1e3`}>
      {`${Math.round(latitude * 100000) / 100000},${Math.round(longitude * 100000) / 100000}`}
    </a>
  );
}

export function LocationsPopup({
  features, map, DISCS_LAYER_ID, startTime,
}) {
  const onMouseOut = () => map.setFilter(DISCS_LAYER_ID, ['==', 'index', -1]);

  return (
    <Container>
      <table onMouseOut={onMouseOut} onBlur={onMouseOut}>
        <thead>
          <tr>
            <th>index</th>
            <th>duration</th>
            <th>distance</th>
            <th>acc</th>
            <th>alt</th>
            <th>coords</th>
            <th>{/* flags */}</th>
          </tr>
        </thead>
        <tbody>
          {features.sort((a, b) => a.properties.index - b.properties.index)
            .map((feature) => {
              const {
                latitude, longitude, index, minutesSinceStart, accuracy, altitude,
                isApproximate, isMocked, distance,
              } = feature.properties;
              const onMouseOver = () => map.setFilter(DISCS_LAYER_ID, ['==', 'index', index]);

              return (
                <tr key={feature.properties.index} onMouseOver={onMouseOver} onFocus={onMouseOver}>
                  <td>{index}</td>
                  <td title={minutesSinceStart && formatDateTime(new Date(new Date(startTime).getTime() + minutesSinceStart * 60000), 'fr')}>
                    {`${minutesSinceStart === undefined ? '?' : minutesSinceStart} min`}
                  </td>
                  <td>{`${Math.round(distance) / 1000} km`}</td>
                  <td>{`${accuracy === undefined ? '?' : Math.round(accuracy * 10) / 10} m`}</td>
                  <td>{`${altitude === undefined ? '?' : altitude} m`}</td>
                  <td>{googleMapsLink(latitude, longitude)}</td>
                  <td>
                    {isApproximate && <span title="isApproximate">🙈</span>}
                    {isMocked && <span title="isMocked">🤖</span>}
                  </td>
                </tr>
              );
            })}
        </tbody>
      </table>
    </Container>
  );
}

LocationsPopup.propTypes = {
  startTime: PropTypes.string.isRequired,
  DISCS_LAYER_ID: PropTypes.string.isRequired,
  features: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  map: PropTypes.shape({
    setFilter: PropTypes.func,
  }).isRequired,
};

export function EventsPopup({ features }) {
  return (
    <Container>
      <table>
        <thead>
          <tr>
            <th>id</th>
            <th>type</th>
            <th>time</th>
            <th>acc</th>
            <th>coords</th>
          </tr>
        </thead>
        <tbody>
          {features.sort(
            (a, b) => a.properties.estimatedTime.localeCompare(b.properties.estimatedTime),
          ).map((feature) => {
            const {
              id, type, latitude, longitude, accuracy, estimatedTime, features: details, color,
            } = feature.properties;

            const preciseTime = `${Intl.DateTimeFormat('fr', {
              year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric',
            }).format(new Date(estimatedTime))} + ${estimatedTime.match(/\.([0-9]+)Z/)[1]}ms`;
            return (
              <React.Fragment key={id}>
                <tr>
                  <td title={id}>
                    <Id>
                      {id}
                    </Id>
                  </td>
                  <td>
                    <span style={{ color }}>⬤</span>
                    {' '}
                    {type}
                  </td>
                  <td title={estimatedTime}>{preciseTime}</td>
                  <td>{`${accuracy === undefined ? '?' : Math.round(accuracy * 10) / 10} m`}</td>
                  <td>{googleMapsLink(latitude, longitude)}</td>
                </tr>
                <tr>
                  <DetailsTd colSpan="5" onClick={(e) => e.target.classList.add('expand')} onMouseOut={(e) => e.target.classList.remove('expand')}>
                    <pre>
                      {JSON.stringify(JSON.parse(details), null, '   ')}
                    </pre>
                  </DetailsTd>
                </tr>
              </React.Fragment>
            );
          })}
        </tbody>
      </table>
    </Container>
  );
}

EventsPopup.propTypes = {
  features: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};
