export const formatDistance = (meters) => {
  if (!meters) {
    return '';
  }
  const km = Math.round(meters / 1000);
  return `${km} km`;
};

export const formatSeconds = (seconds) => {
  if (seconds === 0) {
    return '0min';
  }
  if (!seconds) {
    return '';
  }
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  if (hours === 0) {
    return `${minutes}min`;
  }
  return `${hours}h${minutes < 10 ? '0' : ''}${minutes}`;
};

export const formatDate = (date, language) => (
  Intl.DateTimeFormat(language, {
    year: 'numeric', month: 'long', day: 'numeric',
  }).format(date)
);

export const formatDateTime = (date, language) => (
  Intl.DateTimeFormat(language, {
    year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric',
  }).format(date)
);

// Alternative: https://bundlephobia.com/result?p=@turf/circle@6.0.1
export const createGeoJSONCircle = (center, radiusInMeter, points = 32) => {
  const coords = {
    latitude: center[1],
    longitude: center[0],
  };

  const km = radiusInMeter / 1000;
  const ret = [];
  const distanceX = km / (111.320 * Math.cos(coords.latitude * (Math.PI / 180)));
  const distanceY = km / 110.574;

  let theta;
  let x;
  let y;
  for (let i = 0; i < points; i += 1) {
    theta = (i / points) * (2 * Math.PI);
    x = distanceX * Math.cos(theta);
    y = distanceY * Math.sin(theta);

    ret.push([coords.longitude + x, coords.latitude + y]);
  }

  ret.push(ret[0]);

  return {
    type: 'Feature',
    geometry: {
      type: 'Polygon',
      coordinates: [ret],
    },
  };
};

export function distanceMeters(
  { longitude: lon1, latitude: lat1 },
  { longitude: lon2, latitude: lat2 },
) {
  function toRad(n) {
    return n * (Math.PI / 180);
  }

  const R = 6371000; // Radius of the earth in meters.
  const dLat = toRad(lat2 - lat1);
  const dLon = toRad(lon2 - lon1);
  const a = Math.sin(dLat / 2) * Math.sin(dLat / 2)
          + Math.cos(toRad(lat1)) * Math.cos(toRad(lat2))
          * Math.sin(dLon / 2) * Math.sin(dLon / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  return R * c; // Distance in meters.
}
