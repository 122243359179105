import React from 'react';
import gql from 'graphql-tag';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';

import RideViewLayout from 'components/RideViewLayout';
import RideDetails from 'components/RideDetails';
import config from 'config';
import WaitingForSignal from 'components/WaitingForSignal';
import { RideMapContainer, useRideMap } from 'components/RideMap';
import useMapEmergency, { EmergencyMapStyle } from 'components/ImaCockpit/useMapEmergency';
import RideIdForm from './RideIdForm';
import AdminLinks from './AdminLinks';
import { useMapDebugEvents, useMapDebugLocations } from './mapAdminHook';
import JsonPopup from './JsonPopup';

const Container = styled.div`
  .maplibregl-popup-content {
    padding: 1px;
  }
  .maplibregl-popup-close-button {
    font-size: 20px;
    font-weight: bold;
    background-color: rgba(0,0,0,.2);
    border-radius: 5px;
  }
  .maplibregl-popup-close-button:hover {
    background-color: rgba(0,0,0,.3);
  }
`;
const DetailsContainer = styled.div`
  background: white;
  padding: 0 10px;
`;

export default function MapAdmin({
  ride, debugTrack, debugLocations, authStatus, events, emergencyLocations, sessions,
}) {
  const {
    detailedPolyline, state, user, sharingToken, ...debugRide
  } = ride;
  const [mapRef, map] = useRideMap({
    detailedPolyline,
    state,
    pauses: [],
    displayScale: true,
    initialZoom: 12,
    transparentEnds: true,
    fitToPolyline: true,
    hideStart: !ride.id,
    hideStop: !ride.id,
  });
  useMapEmergency(map, emergencyLocations, { transparent: true });
  useMapDebugLocations(map, { debugLocations, detailedPolyline, startTime: ride.startTime });
  useMapDebugEvents(map, events);

  const emergencySessions = new Set( // Deduplicate.
    events.filter((event) => event.type === 'EMERGENCY')
      .map(({ features: { sessionId } }) => sessionId),
  );
  let storageUrls = [];
  sessions.forEach(({ id: sessionId }) => {
    storageUrls = [
      ...storageUrls,
      [`${sessionId.substr(0, 4)} dbg`, `https://console.firebase.google.com/u/0/project/${config.firebase.clientConfig.projectId}/storage/${config.firebase.debugBucket}/files/~2Fsessions~2F${sessionId}`],
      ['stop', `https://console.firebase.google.com/u/0/project/${config.firebase.clientConfig.projectId}/storage/${config.firebase.debugBucket}/files/~2Fsessions~2F${sessionId}~2Fstops`],
      ...(emergencySessions.has(sessionId) ? [['emerg', `https://console.firebase.google.com/u/0/project/${config.firebase.clientConfig.projectId}/storage/${config.firebase.emergencyBucket}/files/~2Fsessions~2F${sessionId}~2Femergencies`]] : []),
    ];
  });

  return (
    <Container>
      <RideViewLayout
        map={detailedPolyline
          ? <EmergencyMapStyle><RideMapContainer ref={mapRef} /></EmergencyMapStyle>
          : <WaitingForSignal />}
        details={(
          <RideDetails ride={ride} breakpointHeight={900} hideLocation>
            <DetailsContainer>
              <AdminLinks
                rideId={ride.id}
                userId={user && user.id}
                sharingToken={sharingToken}
                sessionIds={sessions.map((session) => session.id)}
                storageUrls={storageUrls}
              />
              <br />
              JSON:
              {' '}
              <JsonPopup
                title="Meta"
                json={{
                  state, debugTrack, user, sessions, debugRide, detailedPolyline,
                }}
              />
              {' '}
              <JsonPopup title="Locations" json={debugLocations} />
              {' '}
              <JsonPopup title="Events" json={events} />
              <RideIdForm rideId={ride.id} sharingToken={sharingToken} />
              {authStatus}
            </DetailsContainer>
          </RideDetails>
        )}
      />
    </Container>
  );
}

MapAdmin.propTypes = {
  ride: PropTypes.shape({
    id: PropTypes.string,
    sharingToken: PropTypes.string,
    user: PropTypes.shape({
      id: PropTypes.string,
    }),
    detailedPolyline: PropTypes.string,
    startTime: PropTypes.string,
    state: PropTypes.string,
  }).isRequired,
  sessions: PropTypes.arrayOf(PropTypes.shape({})),
  events: PropTypes.arrayOf(PropTypes.shape({})),
  debugTrack: PropTypes.shape({}).isRequired,
  debugLocations: PropTypes.arrayOf(PropTypes.shape({})),
  emergencyLocations: PropTypes.arrayOf(PropTypes.shape({})),
  authStatus: PropTypes.node,
};

MapAdmin.defaultProps = {
  debugLocations: [],
  sessions: [],
  events: [],
  emergencyLocations: [],
  authStatus: null,
};

MapAdmin.fragments = {
  mapAdmin: gql`
    fragment MapAdminFragment on Ride {
      sharingToken
      detailedPolyline
      state
      user {
        id
      }
      ...RideDetailsFragment
    }
    ${RideDetails.fragments.rideDetails}
  `,
};
